import React from 'react'
import './Analysis.scss'
const Analysis = () => {
  return (
    <div>
      Analytics
    </div>
  )
}

export default Analysis
